.error-message {
    color: #ff0000;
    background-color: #ffe6e6;
    border: 1px solid #ff0000;
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;
    position: fixed;
    bottom: 10px;
    right: 10px; 
    z-index: 1000;   
    max-width: 30%;
  }

  .success-message {
    color: #00af1a;
    background-color: #baf9d1;
    border: 1px solid #00af1a;
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;
    position: fixed;
    bottom: 10px;
    right: 10px; 
    z-index: 1000;   
    max-width: 30%;
  }