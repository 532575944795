/* Information and submission form */

.submission-page {
  display: flex;
  width: 100%;
}

.submission-info {
  border-right: 1px solid black;
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 18px;
}

.submission-header {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.last-submission {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.last-submission-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.buttons {
  display: flex;
  gap: 18px;
}

.submission-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Test cases */

.test-cases {
  display: flex;
  flex-direction: column;
  width: 60%;
  gap: 18px;
  padding: 18px;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.cases {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.tests {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

.test {
  width: 100px;
  display: flex;
  align-items: center;
  border: 1px solid rgb(187, 182, 182);
  padding: 10px;
  cursor: pointer;
}

.submission-status {
  margin-left: auto;
}

.close-test-info {
  margin-left: auto;
  cursor: pointer;
}

.spinner {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.custom-textarea {
  height: 200px;
  min-height: 100px;
  max-height: 200px;
  overflow-y: scroll;
  padding: 15px;
  border-radius: 8px;
  border: 2px solid #ddd;
  background-color: #f9f9f9;
  font-family: ' sans-serif';
  font-size: 16px;
  color: #333;
  resize: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition:
    border 0.3s,
    box-shadow 0.3s;
}

.custom-textarea:focus {
  outline: none;
  border: 2px solid #7aa6f9;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.test-info-header {
  display: flex;
  align-items: center;
}

.diff-section-header {
  cursor:pointer;
  color:inherit;
  text-decoration: none;
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  align-items: center;
  border:1px;
  padding: 3px;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
}

.diff-section-header:hover {
  background-color: #efefef;
}

.diff-unified-view-option {
  display: flex;
  align-items: center;
}

.diff-unified-view-option > input[type='checkbox'] {
  margin-right: 1vw;
}

.diff-viewer {
  display: block;
}

.hidden-diff-viewer {
  display: none;
}

/* Table */

.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  width: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

/* Tabs */

.tab-container {
  width: 100%;
}

.error-message {
  color: #ff0000;
  background-color: #ffe6e6;
  border: 1px solid #ff0000;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
}
