/* User lists */

.users {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 75vw;
}

.user {
  display: flex;
  align-items: center;
  height: 32px;
}

div.user > label {
  width: fit-content;
  font-size: 18px;
}

.user:nth-of-type(even) {
  background-color: #f3f3f3;
}

.delete-icon {
  margin-left: auto;
  cursor: pointer;
}

.list-header {
  display: flex;
  align-items: center;
  gap: 10px;
}
