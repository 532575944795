/* Modal Overlay */
.assignment-edit-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensures it appears above other elements */
}

/* Modal Container */
.assignment-edit-container {
  background-color: #fff;
  width: 70%;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3); /* Shadow effect */
  position: relative;
  z-index: 1001;
  animation: modalFadeIn 0.3s ease-in-out; /* Fade-in effect */
  display: ruby; /* Create two-column layout */
  gap: 20px;
  flex-direction: row;
  align-items: center; /* Center elements if necessary */
  margin: auto;
  justify-content: space-between; /* Ensure both columns occupy proper space */
}

/* Fade-in Animation */
@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Close Button */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #888;
  transition: color 0.3s;
}

.close-button:hover {
  color: #333;
}

/* Left Column - Form */
.assignment-edit-form {
  flex: 2; /* Takes 2/3 of the space */
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-right: 15px;
}

/* Right Column - Tests */
.assignment-edit-tests {
  flex: 1; /* Takes 1/3 of the space */
  border-left: 1px solid #ddd;
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-height: 500px; /* Limit the height of the tests section */
  overflow-y: auto; /* Add vertical scrolling */
  margin-bottom: 20px;
  margin: 0 auto; /* Center align the content */
}

.assignment-edit-tests h3 {
  font-size: 16px;
  margin-bottom: 10px;
}

.assignment-edit-tests ul {
  list-style: none;
  padding: 0;
}

.assignment-edit-tests li {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.assignment-edit-tests li .test-name {
  flex: 1;
}

.assignment-edit-tests li .test-icon {
  font-size: 12px;
  color: #888;
  margin-left: 10px;
}

/* Scrollbar Styling (optional) */
.assignment-edit-tests::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.assignment-edit-tests::-webkit-scrollbar-thumb {
  background-color: #ccc; /* Color of the scrollbar */
  border-radius: 4px; /* Rounded corners for the scrollbar */
}

.assignment-edit-tests::-webkit-scrollbar-thumb:hover {
  background-color: #bbb; /* Darker color on hover */
}

/* Form Groups */
.form-group {
  margin-bottom: 15px;
}

.form-group label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

.form-group input[type="text"],
.form-group input[type="datetime-local"],
.form-group input[type="number"],
.form-group input[type="file"],
.form-group select {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

/* Buttons */
button {
  display: inline-block;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  background-color: #28a745;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

/* Se esse estilo for necessário colocar uma classe, deixar no elemento generico afeta todos os botões da página */
/* button:hover {
  background-color: #218838;
} */

button.remove {
  background-color: #dc3545;
}

button.remove:hover {
  background-color: #c82333;
}

button.add-test {
  background-color: #007bff;
  margin-top: 10px;
}

button.add-test:hover {
  background-color: #0056b3;
}

.file-input-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

/* File input (hidden) */
.file-input-wrapper input[type="file"] {
  display: none; /* Hide the original input */
}

.file-label {
  background-color: #007bff;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.8rem;
  cursor: pointer;
}

.upload-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.upload-item {
  display: flex;
  align-items: center;
  gap: 5px;
}

.upload-label {
  font-size: 0.9rem;
  font-weight: bold;
  color: white;
  background-color: #007bff; /* Blue background for button look */
  padding: 6px 12px; /* Button padding */
  border-radius: 4px; /* Rounded corners */
  display: inline-block; /* Ensure proper dimensions */
  cursor: pointer; /* Show pointer cursor on hover */
  text-align: center; /* Center text inside the button */
  user-select: none; /* Prevent text selection */
}

.upload-label:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.upload-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.file-name {
  color: #555;
  font-style: italic;
  display: block;
  margin-top: 5px;
  font-size: 0.8rem;
  color: #555;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 200px; 
}

.file-input {
  display: none;
}

/* Save Button Container */
.save-button-container {
  display: flex;
  justify-content: flex-end; /* Aligns the button to the right */
  padding: 10px 20px;
  width: 100%; /* Full width to span below both columns */
  box-sizing: border-box;
  background-color: #f9f9f9;
  border-top: 1px solid #ddd;
  margin-top: -15px; /* Optional: adjust if needed for alignment */
}

.save-button {
  display: inline-block; /* Only take the size of its content */
  padding: 10px 20px; /* Add some padding for a nicer look */
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
  align-self: flex-end; /* Align to the right if it's in a flex container */
  transition: background-color 0.3s;
  width: 100%; /* Make the button span the full width */
}

.save-button:hover {
  background-color: #0056b3;
}

/* Test Item Card */
.test-item {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

/* Buttons and Checkbox Row */
.test-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.test-actions .remove {
  background-color: #dc3545;
  color: #fff;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.test-actions .remove:hover {
  background-color: #c82333;
}

.test-item.unchanged {
  background-color: white;
}

.test-item.updated {
  background-color: #fffbe6; /* Light yellow */
}

.test-item.created {
  background-color: #e6ffe6; /* Light green */
}

.test-item.removed {
  background-color: #ffe6e6; /* Light red */
  text-decoration: line-through;
  opacity: 0.6; /* Make removed tests semi-transparent */
}

.test-actions .remove:disabled {
  background-color: #ccc; /* Gray out remove button if disabled */
  cursor: not-allowed;
}

.test-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.test-item.compact {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
  background-color: #f9f9f9;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border: 1px solid #ddd;
}

.test-item.compact .test-name {
  font-size: 0.8rem;
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.test-item.compact .test-actions {
  display: flex;
  align-items: center;
  gap: 8px;
}

.test-item.compact .remove-btn {
  background: none;
  border: none;
  color: red;
  cursor: pointer;
  font-size: 1rem;
  padding: 0;
}

.toggle-open {
  font-size: 0.8rem;
  display: flex;
  align-items: center;
}

.add-test-section {
  padding: 10px;
  border: 1px dashed #ccc;
  border-radius: 4px;
  background-color: #f7f7f7;
  text-align: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  box-sizing: border-box;
  width: 70%;
  align-self: center;
  margin-top: 0px;
}

.add-test-section .file-inputs {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.add-test-section .file-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.8rem;
}

.add-test-section .file-btn:hover {
  background-color: #0056b3;
}

.add-test-section .add-btn {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
}

.add-test-section .add-btn:hover {
  background-color: #218838;
}

.tests-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns for tests */
  gap: 10px;
  flex: 1;
}
