.tag {
    display: inline-flex;
    align-items: center;
    padding: 8px 12px;
    font-size: 14px;
    border-radius: 16px;
    margin-right: 8px;
    width: fit-content;
    height: fit-content;
}

.label {
    margin-left: 0.5vw;
}

.done,
.green,
.success {
    color: #286132;
    background-color: #C8E6C9;
}

.wip,
.yellow,
.in-progress {
    color: #757030;
    background-color: #e3dba6;
}

.stopped,
.red,
.error {
    color: #742e2e;
    background-color: #FFCDD2;
}

.to-do,
.backlog,
.draft {
    color: #646b70;
    background-color: #cedce8;
}