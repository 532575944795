/* Common dialog styles */

dialog.custom-dialog {
  border: none;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.dialog-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon-container {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.close-icon {
  color: rgb(255, 0, 0);
  font-size: 50px;
  width: 250px;
  height: 250px;
}

/* Styles for the Add dialog */

.user-input-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
}

.user-input {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 10px;
}

/* Create class dialog */

.csvs {
  display: flex;
  gap: 25px;
  margin-bottom: 25px;
}

.csv-input {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
}

.create-class-input {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 500px;
}
