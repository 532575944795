.assignment-time {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 1.6vh;
  font-size: 0.8rem;
}

.assignment-card-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.tabs {
  display: flex;
  cursor: pointer;
}

.tab-button {
  font-size: 16px;
  padding: 10px 20px;
  background-color: #ffffff;
  border: none;
  outline: none;
  color: black;
  transition: background-color 0.3s;
  margin-right: 5px;
}

.tab-button:hover {
  background-color: #ddd;
}

.tab-content {
  padding: 20px;
  border-top: 1px solid #ccc;
}

.tab-button.active {
  border-radius: 0;
  border-bottom: 1px solid black;
}
