.cards {
  display: flex;
  gap: 25px;
  padding: 10px;
  flex-wrap: wrap;
  height: 212px;
  align-items: center;
}

.card {
  border: 1px solid #e0e0e0;
  width: 225px;
  display: flex;
  flex-direction: column;
  padding: 15px;
  gap: 10px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition:
    transform 0.2s ease,
    box-shadow 0.2s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.header {
  display: flex;
  border-bottom: 1px solid black;
  align-items: flex-start;
  flex-direction: column;
}

.status {
  display: flex;
  flex-direction: column;
}
