/* Button to create new elements like tasks, classes, etc */

.create-new-entry-button {
  border: 1px solid #009879;
  background-color: #ffffff;
  color: #009879;
  margin-top: auto;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 5px;
  font-size: 0.9em;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
  cursor: pointer;
}

.create-new-entry-button:hover {
  background-color: #009879;
  color: #ffffff;
}

/* A smaller version of the create button to be placed aligned with texts */

.small-create-new-entry-button {
  background-color: #ffffff;
  color: #009879;
  margin-top: auto;
  padding: 5px;
  font-size: 0.9em;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
  cursor: pointer;
  font-size: 28px;
  border: none;
}

/* Button used to navigate through the pages and main actions */

.main-btn {
  border: 1px solid #009879;
  background-color: #ffffff;
  color: #009879;
  padding: 5px;
  font-size: 0.9em;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
  cursor: pointer;
  margin-top: auto;
  display: flex;
  justify-content: center;
}

.main-btn:hover {
  background-color: #009879;
  color: #ffffff;
}

.button-content-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Modal buttons */

.modal-btn {
  padding: 10px 20px;
  font-size: 0.9em;
  cursor: pointer;
  color: #ffffff;
}

/* Button used to delete elements */

.delete-btn {
  border: 1px solid rgb(255, 0, 0);
  background-color: rgb(255, 0, 0);
}

/* Button used to add elements */

.add-btn {
  border: 1px solid #23cf5c;
  background-color: #23cf5c;
}

/* Button used to cancel an action inside the project */

.cancel-btn {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  border: 1px solid transparent;
}
